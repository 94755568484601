.blocInfos,
.blocInfos h3 {
  color: white;
  text-align: left;
  padding: 2%;
}

.widthInnerContainer {
  width: 80%;
  margin: auto;
}
