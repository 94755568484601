.banner {
  width: 100vw;
}
.editor {
  padding: 2vw 0;
}
.paragraphLeft {
  text-align: left;
}

.lienButton {
  background-color: inherit;
  color: #dd2b25;
}
