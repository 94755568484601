.size {
  height: 300px;
  padding: 0 10vw;
}

.position {
  margin-top: auto;
  margin-bottom: auto;
}

.color {
  font-size: 18px;
  font-weight: 500;
  color: white;
}

.partenaire {
  width: 10vw;
  margin: auto;
}
.partner {
  margin-bottom: 4vw;
}

.partenairesCarousel {
  width: 100%;
}

@media screen and (max-width: 800px) {
  .image {
    width: 12vw;
  }
  .color {
    font-size: 15px;
    color: white;
  }
}
