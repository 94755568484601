.container {
  box-shadow: 5px 5px 2.5px #dfd9d9;
  border: 1px solid #dfd9d9;
  margin-bottom: 4vh;
  padding-bottom: 2vh;
}

.title {
  background-color: #dd2b25;
  padding: 0.8vw;
}

.activityTitle {
  color: white;
  padding-top: 0.8vh;
}
.addActivity {
  background-color: #f9f9f9;
  border: 1px solid #e7e5e5;
  box-shadow: 5px 5px 2.5px #dfd9d9;
  padding: 2vw;
  margin-top: 2vw;
}
.input {
  box-shadow: 5px 5px 2.5px #dfd9d9;
  border-radius: 0;
}

.inputEnd {
  box-shadow: 5px 5px 2.5px #dfd9d9;
  border-radius: 0;
  margin-bottom: 2vw;
}
.containerList {
  margin: 2vw 2vw;
}

.textCard {
  text-align: left;
  font-size: 0.8vw;
  text-align: left;
}

.card {
  margin-bottom: 1vw;
}
.titleModal {
  text-align: center;
  margin-top: 1vw;
}
.buttonCancel {
  padding: 8px 20px !important;
  border: 1px solid transparent;
  box-shadow: 5px 5px 2.5px #dfd9d9;
  background-color: white;
  color: black;
  border-radius: 0;
}
.buttonCancel:hover {
  border: 1px solid #dd2b25;
  background-color: white;
  color: black;
}
.subtitle {
  text-align: center;
  font-size: 0.8vw !important;
  margin-bottom: 2vw;
}
