.drapeau {
  width: 25px;
}
.bgDrapeau {
  background-color: none !important;
  border-color: none !important;
}
.dropdowm {
  min-width: 40px !important;
}
.nav {
  display: flex;
  position: fixed;
  width: 100vw;
  height: 7vh;
  z-index: 1000;
}
.navbar {
  justify-content: flex-end;
  width: 100%;
  align-items: center;
  font-size: 14px;
}
.banner {
  width: 100%;
}
.networking {
  float: left;
  top: -35vh;
  position: relative;
  width: 50vw;
}
.navItem {
  cursor: pointer;
  padding: 0 !important;
}

@media screen and (max-width: 640px) {
  .banner {
    display: none;
  }
}
