.activeOn {
  background-color: #dd2b25 !important;
  color: white;
  cursor: pointer;
}

.activeOff {
  color: #c2c2c2;
  cursor: pointer;
  border: transparent;
}

.activeOff h4 {
  color: #c2c2c2 !important;
}

.activeOn h4,
.activeOff h4 {
  font-size: 20px;
}

.cross {
  cursor: pointer;
}
