.global {
  margin-top: 5vw;
  border: 1px solid #e7e5e5;
  box-shadow: 5px 5px 2.5px #dfd9d9;
  padding: 2vw;
}

.top {
  text-align: justify;
  align-items: center;
}

.topleft {
  background-color: #f9f9f9;
  align-items: center;
}

.topright {
  margin-left: 2vw;
}

.bottom {
  text-align: justify;
  margin-top: 2vw;
  padding-right: 1.5vw;
}

.btn {
  justify-content: center;
  margin-top: 1.5vw;
  margin-bottom: 0.5vw;
}

/* .justify {
  text-align: justify;
} */

@media screen and (max-width: 640px) {
  .top {
    margin-top: 120px;
  }
}
