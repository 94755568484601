@import url(https://fonts.googleapis.com/css2?family=Raleway:wght@200;300;400;500;600;700;800;900&display=swap);
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #28262b;
}
hr {
  width: 30%;
  background-color: #dd2b25;
  margin: 0;
  height: 0.3vw !important;
}
h1 {
  font-size: 4.5vw !important;
  color: white;
  line-height: 1.5;
  font-weight: 800;
  text-transform: uppercase;
}

.breadcrumb {
  background-color: inherit;
}

.breadcrumb a {
  color: #28262b !important;
}
h2 {
  font-size: 30px !important;
  color: #28262b;
  line-height: 1.5;
  font-weight: 800;
  padding: 20px;
  text-transform: uppercase;
}
h3 {
  margin: 20px;
  font-size: 25px;
  color: #28262b;
  line-height: 1.4;
  font-weight: 800;
}
button {
  padding: 8px 20px;
  background-color: #dd2b25;
  font-size: 18px;
  font-weight: 500;
  color: white;
  border: 1px solid transparent;
  border-radius: 0 !important;
}
button:hover {
  background-color: transparent;
  color: #dd2b25;
  border: 1px solid #dd2b25;
  transition: all 0.4s ease-in-out;
}
p {
  font-size: 16px;
  font-weight: 500;
}
.redCrea {
  background-color: #dd2b25;
  margin-top: 4vw;
}
.lightGreyCrea {
  background-color: #f9f9f9;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.hrFooter {
  height: 0.1vw !important;
  width: 40vw;
  margin: 6vw 30vw 2vw 30vw;
}
.button {
  padding: 10px 20px !important;
  background-color: #dd2b25 !important;
  font-size: 15px !important;
  color: white !important;
  border: 1px solid transparent !important;
  border-radius: 0 !important;
}

/*Editeur de texte */
.ck-content {
  height: 20vw;
}

/* Fil d'ariane */
.breadcrumb {
  margin: 1vw 0 2vw 0;
  background-color: transparent !important;
}
.breadcrumb a {
  color: black;
  text-decoration: none;
}
.breadcrumb a:hover {
  color: blue;
  text-decoration: none;
}
/*textarea formualire contact*/
.textarea .form-control {
  height: 20vh;
}

/*Menu déroulant choix faq admin*/
.dropdown-menu {
  width: auto !important;
  text-align: center;
}
.dropdown-item {
  font-size: 0.8vw;
}

/*modal faq*/
.modal-dialog {
  max-width: 800px;
}
.modal-title {
  width: 100%;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

body {
  font-family: 'Raleway';
}
.Dashboard_userInfos__gZWs7 {
  background-color: #dd2b25;
  margin-top: 2vw;
  margin-bottom: 2vh;
  padding: 0;
}
.Dashboard_dashboardTable__iH0UF {
  margin-top: 3vw;
  margin-bottom: 2vw;
  margin-right: auto;
  margin-left: auto;
  padding: 0;
}
.Dashboard_dashboard__3b5AM {
  margin: 0 !important;
  padding: 0;
}
.Dashboard_fil__1AMk0 {
  margin-left: 15vw;
}

.User_Infos_blocInfos__Nvae-,
.User_Infos_blocInfos__Nvae- h3 {
  color: white;
  text-align: left;
  padding: 2%;
}

.User_Infos_widthInnerContainer__25_8w {
  width: 80%;
  margin: auto;
}

.Dashboard_table_activeOn__1k2L4 {
  background-color: #dd2b25 !important;
  color: white;
  cursor: pointer;
}

.Dashboard_table_activeOff__2FU8Y {
  color: #c2c2c2;
  cursor: pointer;
  border: transparent;
}

.Dashboard_table_activeOff__2FU8Y h4 {
  color: #c2c2c2 !important;
}

.Dashboard_table_activeOn__1k2L4 h4,
.Dashboard_table_activeOff__2FU8Y h4 {
  font-size: 20px;
}

.Dashboard_table_cross__jTiXn {
  cursor: pointer;
}

.footer_bkgFooter__1oD8- {
  background-color: #f9f9f9;
  text-align: left;
  color: #acacac;
}

.footer_colorLink__1ozpb {
  color: #acacac;
}

.footer_iconFooter__3Dpo4 {
  color: #dd2b25;
}

.footer_imgWidthCrea__2DPAh {
  width: 50%;
}
.footer_imgWidthNetworking__3OmSp {
  width: 60%;
}

.footer_tagMl__DB_xB {
  color: #28262b;
  text-align: center;
}

.footer_textBold__2QTav {
  font-weight: bold;
}

@media screen and (max-width: 640px) {
  .footer_bkgFooter__1oD8- {
    text-align: center;
  }
  .footer_imgWidthCrea__2DPAh {
    width: 50%;
  }
  .footer_imgWidthNetworking__3OmSp {
    width: 80%;
  }
}

.NavBar_drapeau__1sAx7 {
  width: 25px;
}
.NavBar_bgDrapeau__tZijA {
  background-color: none !important;
  border-color: none !important;
}
.NavBar_dropdowm__3SC_q {
  min-width: 40px !important;
}
.NavBar_nav__3yOnC {
  display: -webkit-flex;
  display: flex;
  position: fixed;
  width: 100vw;
  height: 7vh;
  z-index: 1000;
}
.NavBar_navbar__1tG_B {
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  width: 100%;
  -webkit-align-items: center;
          align-items: center;
  font-size: 14px;
}
.NavBar_banner__ccwK6 {
  width: 100%;
}
.NavBar_networking__3ECS_ {
  float: left;
  top: -35vh;
  position: relative;
  width: 50vw;
}
.NavBar_navItem__3mZBN {
  cursor: pointer;
  padding: 0 !important;
}

@media screen and (max-width: 640px) {
  .NavBar_banner__ccwK6 {
    display: none;
  }
}

.PopUpConnection_formgroup__J9NNO {
  padding: 0 4vw;
}

.PopUpConnection_buttonLien__1VjL2 {
  background-color: inherit;
  border: none;
  color: #dd2b25;
  margin: 5px 10px;
}

.PopUpConnection_buttonLien__1VjL2:hover {
  background-color: inherit;
  border: none;
  color: #dd2b25;
  margin: 5px 10px;
}

.PopUpConnection_question__1U6oP {
  text-align: center;
}
.PopUpConnection_button__3smgm {
  padding: 10px 20px !important;
  background-color: #dd2b25 !important;
  font-size: 15px !important;
  color: white !important;
  border: 1px solid transparent !important;
  border-radius: 0 !important;
}
.PopUpConnection_navlink__17jyq {
  cursor: pointer;
}
.PopUpConnection_error__36eFx {
  font-size: 1rem;
  color: red;
  font-weight: bold;
  text-align: center;
}

@media screen and (max-width: 640px) {
  .PopUpConnection_alignRespCenter__2TuHv {
    text-align: center;
  }
}

.ForgotPassword_button__16QuW {
  color: #007bff;
  font-weight: bold;
}
.ForgotPassword_buttonCancel__3Qll4 {
  background-color: inherit;
  border: none;
  color: #dd2b25;
  margin: 5px 10px;
}

.ForgotPassword_buttonCancel__3Qll4:hover {
  background-color: inherit;
  border: none;
  color: #dd2b25;
  margin: 5px 10px;
}

.Account_buttonCancel__1PVgT {
  padding: 8px 20px !important;
  border: 1px solid transparent;
  box-shadow: 5px 5px 2.5px #dfd9d9;
  background-color: white;
  color: black;
  border-radius: 0;
}
.Account_buttonCancel__1PVgT:hover {
  border: 1px solid #dd2b25;
  background-color: white;
  color: black;
}
.Account_rowInput__yKKMx {
  margin: 1vw 0;
}

.SecondNavbar_logo_crea__HGxOk {
  width: 5vw;
  margin-left: 2vw;
}
.SecondNavbar_navbar__pPIjU {
  background-color: rgb(255, 255, 255, 0.8);
  position: fixed !important;
  width: 100%;
  margin-top: 7vh;
  z-index: 500;
  -webkit-justify-content: space-between !important;
          justify-content: space-between !important;
}

.SecondNavbar_alignCenter__Z8CQA {
  -webkit-align-items: center !important;
          align-items: center !important;
}

.SecondNavbar_navlink__2qIxB:hover {
  color: #dd2b25;
}
@media screen and (max-width: 800px) {
  .SecondNavbar_partenaires__1b7JA,
  .SecondNavbar_contact__JdzRV {
    padding-left: inherit;
    padding-right: inherit;
  }

  .SecondNavbar_marginLeftResp__2lypD {
    margin-left: 0px !important;
  }

  .SecondNavbar_logo_crea__HGxOk {
    display: none;
  }
  .SecondNavbar_toggleButton__2NeBR {
    /* background-color: #dd2b25; */
    padding: 3px 8px;
  }

  .SecondNavbar_navbar__pPIjU {
    background-color: white;
  }
}

.Announce_global__FfSkG {
  margin-top: 5vw;
  border: 1px solid #e7e5e5;
  box-shadow: 5px 5px 2.5px #dfd9d9;
  padding: 2vw;
}

.Announce_top__1UVuW {
  text-align: justify;
  -webkit-align-items: center;
          align-items: center;
}

.Announce_topleft__f-DHi {
  background-color: #f9f9f9;
  -webkit-align-items: center;
          align-items: center;
}

.Announce_topright__P_Sg9 {
  margin-left: 2vw;
}

.Announce_bottom__2BPHe {
  text-align: justify;
  margin-top: 2vw;
  padding-right: 1.5vw;
}

.Announce_btn__XxCOy {
  -webkit-justify-content: center;
          justify-content: center;
  margin-top: 1.5vw;
  margin-bottom: 0.5vw;
}

/* .justify {
  text-align: justify;
} */

@media screen and (max-width: 640px) {
  .Announce_top__1UVuW {
    margin-top: 120px;
  }
}

.ApplyModale_inputTitle__2ohq5 {
  margin-bottom: 1vw;
}
.ApplyModale_inputResume__22Jg2 {
  margin-top: 1vw;
}

.blocs_lienButton__hGLLA {
  background-color: inherit;
  color: #dd2b25;
}

.blocs_blocsImgResp__2Ay8T {
  display: none;
}

@media screen and (max-width: 800px) {
  .blocs_blocsImgResp__2Ay8T {
    display: inline-block !important;
  }
  .blocs_blocsImg__3CL-R {
    display: none !important;
  }
}

.Steps_size__1MrZc {
  height: 300px;
  padding: 0 10vw;
}

.Steps_position__1KK4i {
  margin-top: auto;
  margin-bottom: auto;
}

.Steps_color__i_Xbv {
  font-size: 18px;
  font-weight: 500;
  color: white;
}

.Steps_partenaire__2dC7b {
  width: 10vw;
  margin: auto;
}
.Steps_partner__ZM9hT {
  margin-bottom: 4vw;
}

.Steps_partenairesCarousel__1DVco {
  width: 100%;
}

@media screen and (max-width: 800px) {
  .Steps_image__3n_1Z {
    width: 12vw;
  }
  .Steps_color__i_Xbv {
    font-size: 15px;
    color: white;
  }
}

@font-face {
  font-family: 'Raleway';
  src: url(/static/media/Raleway-Bold.38bff7e0.ttf);
}

@media screen and (max-width: 640px) {
  .Header_presentation__3OBks {
    padding-top: 100px;
    margin-top: 0 !important;
  }
}

.Partners_colLogo__3Q-L0 {
  box-shadow: 5px 5px 2.5px #dfd9d9;
  border: 1px solid #dfd9d9;
  margin: 2vw;
  cursor: pointer;
}
.Partners_rowHeaders__3pR1k {
  width: 100%;
  -webkit-align-items: center;
          align-items: center;
}
.Partners_logo__2iA2V {
  width: 8vw;
}

.Partners_logoPartner__2GhPz {
  width: 8vw;
}
.Partners_rowPartners__2FDaW {
  box-shadow: 5px 5px 2.5px #dfd9d9;
  border: 1px solid #dfd9d9;
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly;
}

.PostAnnounce_banner__11zsx {
  width: 100vw;
}
.PostAnnounce_editor__2xCD1 {
  padding: 2vw 0;
}
.PostAnnounce_paragraphLeft__2RzPp {
  text-align: left;
}

.PostAnnounce_lienButton__BQ6q1 {
  background-color: inherit;
  color: #dd2b25;
}

.CreateAccount_banner__1vqS6 {
  width: 100%;
}
.CreateAccount_container__1Sym2 {
  text-align: left;
  padding: 3vh 0 5vh 0;
}
.CreateAccount_buttonValidate__3T47J {
  float: right !important;
}
.CreateAccount_champs__26Uqn {
  font-size: 0.6vw;
}
.CreateAccount_checkbox__3XHmw {
  height: 2rem;
}
.CreateAccount_label__1rOZF {
  font-size: 16px;
  font-weight: 300;
}
.CreateAccount_formgroupSchool__3-V89 {
  padding-top: 1vh;
  padding-bottom: 3vh;
}
.CreateAccount_formgroup__2rCsE {
  padding-top: 1vh;
  padding-bottom: 1vh;
}

@media screen and (max-width: 640px) {
  .Contact_form__29_Pw {
    margin-top: 50px;
  }
}

/* .graph {
  width: 60vw;
  margin-bottom: 10vh;
} */

.DashboardAdmin_userTypes__3EOhU {
  background-color: #28262b;
  color: white;
  padding: 10px;
}

.DashboardAdmin_pie__2K_2X {
  box-shadow: 5px 5px 2.5px #dfd9d9;
  border: 1px solid #dfd9d9;
  padding: 0;

  width: 100%;
}
.DashboardAdmin_line__2MWL- {
  box-shadow: 5px 5px 2.5px #dfd9d9;
  border: 1px solid #dfd9d9;
  padding: 0;
  padding-bottom: 4vh;
}
/* .bar2 {
  margin-top: 10vh;
} */
/* .containerGeneral {
  margin-bottom: 10vh;
} */
.DashboardAdmin_nbtotal__1kdF1 {
  margin-top: 2vw;
  text-align: center;
  margin-left: 2vw;
  font-size: 1vw;
  font-weight: bold;
}
.DashboardAdmin_nbtotal2__1N3eb {
  text-align: center;
  margin-left: 2vw;
  font-size: 1vw;
  font-weight: bold;
}
.DashboardAdmin_nb__63TzS {
  text-align: left;
  margin-left: 7vw;
  font-size: 0.8vw;
}

.header-table-red .ant-table-thead .ant-table-cell {
  background-color: #dd2b25;
  color: white;
}

.Users_List_Table_theadBackground__U6ytE {
  background-color: #dd2b25;
  color: white;
}

.Users_List_Table_buttonInput__2tB1D {
  background-color: transparent;
  color: black;
  border: 1px solid black;
  margin: 5px;
}

.Users_List_Table_export__2-AmE {
  -webkit-justify-content: right;
          justify-content: right;
}

.Users_List_Table_thFontsize__1NLT9 {
  font-size: 12px;
}
.Users_List_Table_okButton__VR2k0 {
  background-color: white;
  color: #dd2b25;
  border-color: #dd2b25;
  width: 100%;
}

.Users_List_Table_suppButton__1pnx2 {
  background-color: white;
  color: #dd2b25;
  border-color: #dd2b25;
}
.Users_List_Table_okButton__VR2k0:hover {
  background-color: #dd2b25;
  color: white;
  border-color: white;
}
.Users_List_Table_inputSearch__1wHSP {
  border-radius: 0;
  border-color: #dd2b25;
}

.Users_List_Table_paddingRightNone__3iovQ {
  padding-right: inherit !important;
}
.Users_List_Table_cross__2157N {
  cursor: pointer;
}

.Announces_theadBackground__peFtB {
  background-color: black;
  color: white;
}

.Announces_buttonInput__jcxzt {
  background-color: transparent;
  color: black;
  border: 1px solid black;
  margin: 5px;
}

.Announces_cross__1q3uS {
  cursor: pointer;
}

.header-table-black .ant-table-thead .ant-table-cell {
  background-color: black;
  color: white;
}

.Faq_List_searchBar__1PSrg {
  background-color: #dd2b25;
  padding: 0.8vw;
}
.Faq_List_faqTitle__2apmH {
  color: white;
  padding-top: 0.8vh;
}
.Faq_List_inputSearch__2tLWF {
  border-radius: 0;
  width: 10vw;
}
.Faq_List_addQR__1FFC7 {
  margin: 2vw 0;
  background-color: #f9f9f9;
  border: 1px solid #e7e5e5;
  box-shadow: 5px 5px 2.5px #dfd9d9;
  padding: 2vw;
}
.Faq_List_inputQR__3MS_A {
  margin: 1vw 0;
  box-shadow: 5px 5px 2.5px #dfd9d9;
  border-radius: 0;
}
.Faq_List_checkbox__3do6s {
  text-align: left;
  font-size: 0.8vw;
  text-align: left;
  font-weight: bold;
  margin-right: 10vw;
  margin-bottom: 1vw;
}

.Faq_List_response__3SrGw {
  font-size: 0.8vw;
  text-align: left;
}

.Faq_List_buttonDropdown__34_zx {
  padding: 8px 20px !important;
  border: 1px solid transparent;
  box-shadow: 5px 5px 2.5px #dfd9d9;
  background-color: white;
  color: black;
  border-radius: 0;
}
.Faq_List_buttonDropdown__34_zx:hover {
  border: 1px solid #dd2b25;
  background-color: white;
  color: black;
}
.Faq_List_item__3BHVY {
  font-size: 0.8vw;
}
.Faq_List_item__3BHVY:hover {
  border: 1px solid #dfd9d9;
}
.Faq_List_containerQR__1qk9r {
  box-shadow: 5px 5px 2.5px #dfd9d9;
  border: 1px solid #dfd9d9;
  margin-bottom: 4vh;
  padding-bottom: 2vh;
}
.Faq_List_container__3KIpT {
  margin: 0 2vw;
}
/*FaqModal*/
.Faq_List_title__3sei_ {
  text-align: center;
  margin-top: 1vw;
}
.Faq_List_subtitle__3S3qL {
  text-align: center;
  font-size: 0.8vw !important;
  margin-bottom: 2vw;
}
.Faq_List_question__K3rAU {
  box-shadow: 5px 5px 2.5px #dfd9d9;
  border: 1px solid #dfd9d9;
  margin-bottom: 1vw;
  padding-top: 0.5vw;
}
.Faq_List_reponse__2hFpJ {
  box-shadow: 5px 5px 2.5px #dfd9d9;
  border: 1px solid #dfd9d9;
  padding: 1vw;
  margin-bottom: 2vw;
  height: 20vh !important;
}

.Partner_searchBar__2WVPV {
  background-color: #28262b;
  padding: 0.8vw;
}
.Partner_faqTitle__3Hvcm {
  color: white;
  padding-top: 0.8vh;
}
.Partner_inputSearch__3Nsbx {
  border-radius: 0;
  width: 10vw;
}
.Partner_addQR__qvrvz {
  margin: 2vw 0;
  background-color: #f9f9f9;
  border: 1px solid #e7e5e5;
  box-shadow: 5px 5px 2.5px #dfd9d9;
  padding: 2vw;
}
.Partner_inputQR__1FucE {
  margin: 1vw 0;
  box-shadow: 5px 5px 2.5px #dfd9d9;
  border-radius: 0;
}
.Partner_checkbox__3Iyxn {
  text-align: left;
  font-size: 0.8vw;
  text-align: left;
  font-weight: bold;
  margin-right: 10vw;
  margin-bottom: 1vw;
}

.Partner_response__1qPnB {
  font-size: 0.8vw;
  text-align: left;
  margin-bottom: 2vw;
  padding-left: 1vw;
}
.Partner_buttonDropdown__31xn2 {
  padding: 8px 20px !important;
  border: 1px solid transparent;
  box-shadow: 5px 5px 2.5px #dfd9d9;
  background-color: white;
  color: black;
  border-radius: 0;
}
.Partner_buttonDropdown__31xn2:hover {
  border: 1px solid #dd2b25;
  background-color: white;
  color: black;
}
.Partner_item__3o99C {
  font-size: 0.8vw;
}
.Partner_item__3o99C:hover {
  border: 1px solid #dfd9d9;
}
.Partner_containerQR__2FxnI {
  box-shadow: 5px 5px 2.5px #dfd9d9;
  border: 1px solid #dfd9d9;
  margin-bottom: 4vh;
  padding-bottom: 2vh;
}
.Partner_container__17SGj {
  margin: 0 2vw;
}
/*FaqModal*/
.Partner_title__1TJaO {
  text-align: center;
  margin-top: 1vw;
}
.Partner_subtitle__1bOY6 {
  text-align: center;
  font-size: 0.8vw !important;
  margin-bottom: 2vw;
}
.Partner_input__1gWQl {
  box-shadow: 5px 5px 2.5px #dfd9d9;
  border: 1px solid #dfd9d9;
  margin-bottom: 1vw;
  padding-top: 0.5vw;
}
.Partner_reponse__g67u9 {
  box-shadow: 5px 5px 2.5px #dfd9d9;
  border: 1px solid #dfd9d9;
  padding: 1vw;
  margin-bottom: 2vw;
  height: 20vh !important;
}
.Partner_logo__2-otd {
  text-align: left;
}
.Partner_titreDescription__b7Y1b {
  text-align: left;
  padding-left: 1vw;
}
.Partner_row__3Zchj {
  margin-bottom: 8vh;
}
.Partner_star__1_W0K {
  width: 1.5vw;
}

.Partner_logoPartner__29Foq {
  width: 8vw;
}

.ListAnnounceUser_cardSubtitleDate__mc1NV {
  font-size: 1rem;
  text-align: left;
  font-weight: 300;
  padding-bottom: 1.1vw;
  font-style: italic;
}
.ListAnnounceUser_cardCss__1DHaR {
  margin-top: 2vw;
}
.ListAnnounceUser_cardTitle__1BFfN {
  font-size: 1.2rem;
  text-align: left;
}

.ListAnnounceUser_cardSubtitle__FPm6K {
  text-align: left;
  font-weight: 300;
  font-size: 1.25rem;
  font-style: italic;
  padding-bottom: 0.5vw;
}
.ListAnnounceUser_cardText__1uW1n {
  text-align: left !important;
  height: 8vh;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ListAnnounceUser_button__24rX9 {
  color: #dd2b25;
  background-color: inherit;
  border: 1px solid transparent;
}
.ListAnnounceUser_button__24rX9:hover {
  color: #dd2b25;
  background-color: inherit;
  border: 1px solid #dd2b25;
}

.ListAnnounceUser_button__24rX9:focus {
  color: #dd2b25;
  background-color: inherit;
  border: 1px solid #dd2b25;
}

.JobCategory_container__2ahX3 {
  box-shadow: 5px 5px 2.5px #dfd9d9;
  border: 1px solid #dfd9d9;
}

.JobCategory_title__15zrM {
  background-color: #dd2b25;
}

.JobCategory_activityTitle__SA-gZ {
  color: white;
}
.JobCategory_addActivity__msUHZ {
  background-color: #f9f9f9;
  border: 1px solid #e7e5e5;
  box-shadow: 5px 5px 2.5px #dfd9d9;
}
.JobCategory_input__1R6Qs {
  box-shadow: 5px 5px 2.5px #dfd9d9;
  border-radius: 0;
}

.JobCategory_inputEnd__3eL56 {
  box-shadow: 5px 5px 2.5px #dfd9d9;
  border-radius: 0;
}

.JobCategory_textCard__1udiV {
  text-align: left;
  font-size: 12px;
  text-align: left;
}

.JobCategory_titleModal__3XZE- {
  text-align: center;
}
.JobCategory_buttonCancel__1S2nN {
  padding: 8px 20px !important;
  border: 1px solid transparent;
  box-shadow: 5px 5px 2.5px #dfd9d9;
  background-color: white;
  color: black;
  border-radius: 0;
}
.JobCategory_buttonCancel__1S2nN:hover {
  border: 1px solid #dd2b25;
  background-color: white;
  color: black;
}
.JobCategory_subtitle__w-_IT {
  text-align: center;
  font-size: 12px !important;
}

.UserTypes_container__5HzOU {
  box-shadow: 5px 5px 2.5px #dfd9d9;
  border: 1px solid #dfd9d9;
  margin-bottom: 4vh;
  padding-bottom: 2vh;
}

.UserTypes_title__1qp4E {
  background-color: #dd2b25;
  padding: 0.8vw;
}

.UserTypes_activityTitle__azG-t {
  color: white;
  padding-top: 0.8vh;
}
.UserTypes_addActivity__3GYrt {
  background-color: #f9f9f9;
  border: 1px solid #e7e5e5;
  box-shadow: 5px 5px 2.5px #dfd9d9;
  padding: 2vw;
  margin-top: 2vw;
}
.UserTypes_input__zH-ji {
  box-shadow: 5px 5px 2.5px #dfd9d9;
  border-radius: 0;
}

.UserTypes_inputEnd__G1uZ9 {
  box-shadow: 5px 5px 2.5px #dfd9d9;
  border-radius: 0;
  margin-bottom: 2vw;
}
.UserTypes_containerList__268e6 {
  margin: 2vw 2vw;
}

.UserTypes_textCard__3hrLe {
  text-align: left;
  font-size: 0.8vw;
  text-align: left;
}

.UserTypes_card__Sh0Wl {
  margin-bottom: 1vw;
}
.UserTypes_titleModal__3r36N {
  text-align: center;
  margin-top: 1vw;
}
.UserTypes_buttonCancel__1xyXk {
  padding: 8px 20px !important;
  border: 1px solid transparent;
  box-shadow: 5px 5px 2.5px #dfd9d9;
  background-color: white;
  color: black;
  border-radius: 0;
}
.UserTypes_buttonCancel__1xyXk:hover {
  border: 1px solid #dd2b25;
  background-color: white;
  color: black;
}
.UserTypes_subtitle__157Si {
  text-align: center;
  font-size: 0.8vw !important;
  margin-bottom: 2vw;
}

.TypeAnnounce_containerQR__pSoJK {
  box-shadow: 5px 5px 2.5px #dfd9d9;
  border: 1px solid #dfd9d9;
  margin-bottom: 4vh;
  padding-bottom: 2vh;
}
.TypeAnnounce_addTypePost__CbTui {
  margin: 2vw 0;
  background-color: #f9f9f9;
  border: 1px solid #e7e5e5;
  box-shadow: 5px 5px 2.5px #dfd9d9;
  padding: 2vw;
}
.TypeAnnounce_searchBar__1SjY5 {
  background-color: #28262b;
  padding: 0.8vw;
}
.TypeAnnounce_faqTitle__2wlOH {
  color: white;
  padding-top: 0.8vh;
}
.TypeAnnounce_input__1-OmL {
  box-shadow: 5px 5px 2.5px #dfd9d9;
  border-radius: 0;
}
.TypeAnnounce_modalText__2PzyE {
  margin-top: auto;
  margin-bottom: auto;
}
.TypeAnnounce_inputQR__2p2sx {
  margin: 1vw 0;
  box-shadow: 5px 5px 2.5px #dfd9d9;
  border-radius: 0;
}

.TypeAnnounce_imgCard__2BwXi {
  width: 100%;
}
.TypeAnnounce_titleModal__29zQH {
  text-align: center;
  margin-top: 1vw;
}
.TypeAnnounce_subtitle__15pWK {
  text-align: center;
  font-size: 0.8vw !important;
  margin-bottom: 2vw;
}
.TypeAnnounce_buttonCancel__2Bcx1 {
  padding: 8px 20px !important;
  border: 1px solid transparent;
  box-shadow: 5px 5px 2.5px #dfd9d9;
  background-color: white;
  color: black;
  border-radius: 0;
}
.TypeAnnounce_buttonCancel__2Bcx1:hover {
  border: 1px solid #dd2b25;
  background-color: white;
  color: black;
}

.ActivityFields_container__3tazq {
  box-shadow: 5px 5px 2.5px #dfd9d9;
  border: 1px solid #dfd9d9;
  margin-bottom: 4vh;
  padding-bottom: 2vh;
}

.ActivityFields_title__28oVL {
  background-color: #dd2b25;
  padding: 0.8vw;
}

.ActivityFields_activityTitle__3CoZu {
  color: white;
  padding-top: 0.8vh;
}
.ActivityFields_addActivity__1WpMo {
  background-color: #f9f9f9;
  border: 1px solid #e7e5e5;
  box-shadow: 5px 5px 2.5px #dfd9d9;
  padding: 2vw;
  margin-top: 2vw;
}
.ActivityFields_input__2P_Rr {
  box-shadow: 5px 5px 2.5px #dfd9d9;
  border-radius: 0;
}

.ActivityFields_inputEnd__128Nf {
  box-shadow: 5px 5px 2.5px #dfd9d9;
  border-radius: 0;
  margin-bottom: 2vw;
}
.ActivityFields_containerList__2gkXC {
  margin: 2vw 2vw;
}

.ActivityFields_textCard__ZHWOQ {
  text-align: left;
  font-size: 0.8vw;
  text-align: left;
}

.ActivityFields_card__3z8Pw {
  margin-bottom: 1vw;
}
.ActivityFields_titleModal__1DA51 {
  text-align: center;
  margin-top: 1vw;
}
.ActivityFields_buttonCancel__1JSLv {
  padding: 8px 20px !important;
  border: 1px solid transparent;
  box-shadow: 5px 5px 2.5px #dfd9d9;
  background-color: white;
  color: black;
  border-radius: 0;
}
.ActivityFields_buttonCancel__1JSLv:hover {
  border: 1px solid #dd2b25;
  background-color: white;
  color: black;
}
.ActivityFields_subtitle__1A_Ch {
  text-align: center;
  font-size: 0.8vw !important;
  margin-bottom: 2vw;
}

.FaqUser_container__CCdv4 {
  box-shadow: 5px 5px 2.5px #dfd9d9, inset 0px 0px 1px #dd2b25;
  margin-top: 1vw;
  margin-right: auto;
  margin-left: auto;
  text-align: left;
  width: 70%;
}

.FaqUser_question__V-zXg {
  margin-top: 3vw;
  border-bottom: 0.1vw solid #dfd9d9;
  width: 100% !important;
  padding: 0.5vw;
  font-weight: bold;
}
.FaqUser_answer__3f9b3 {
  padding: 1vw 1vw 1vw 2vw;
  font-weight: bold;
}

.Put_container__2A-6_ {
  text-align: left;
}

.Put_no__MJA7j {
  padding: 8px 20px !important;
  border: 1px solid transparent;
  box-shadow: 5px 5px 2.5px #dfd9d9;
  background-color: white;
  color: black;
  border-radius: 0;
}
.Put_no__MJA7j:hover {
  border: 1px solid #dd2b25;
  background-color: white;
  color: black;
}

.Put_buttonCancel__2k3-t {
  padding: 8px 20px !important;
  border: 1px solid transparent;
  box-shadow: 5px 5px 2.5px #dfd9d9;
  background-color: white;
  color: black;
  border-radius: 0;
}
.Put_buttonCancel__2k3-t:hover {
  border: 1px solid #dd2b25;
  background-color: white;
  color: black;
}

