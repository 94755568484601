.banner {
  width: 100%;
}
.container {
  text-align: left;
  padding: 3vh 0 5vh 0;
}
.buttonValidate {
  float: right !important;
}
.champs {
  font-size: 0.6vw;
}
.checkbox {
  height: 2rem;
}
.label {
  font-size: 16px;
  font-weight: 300;
}
.formgroupSchool {
  padding-top: 1vh;
  padding-bottom: 3vh;
}
.formgroup {
  padding-top: 1vh;
  padding-bottom: 1vh;
}
