.formgroup {
  padding: 0 4vw;
}

.buttonLien {
  background-color: inherit;
  border: none;
  color: #dd2b25;
  margin: 5px 10px;
}

.buttonLien:hover {
  background-color: inherit;
  border: none;
  color: #dd2b25;
  margin: 5px 10px;
}

.question {
  text-align: center;
}
.button {
  padding: 10px 20px !important;
  background-color: #dd2b25 !important;
  font-size: 15px !important;
  color: white !important;
  border: 1px solid transparent !important;
  border-radius: 0 !important;
}
.navlink {
  cursor: pointer;
}
.error {
  font-size: 1rem;
  color: red;
  font-weight: bold;
  text-align: center;
}

@media screen and (max-width: 640px) {
  .alignRespCenter {
    text-align: center;
  }
}
