.container {
  box-shadow: 5px 5px 2.5px #dfd9d9;
  border: 1px solid #dfd9d9;
}

.title {
  background-color: #dd2b25;
}

.activityTitle {
  color: white;
}
.addActivity {
  background-color: #f9f9f9;
  border: 1px solid #e7e5e5;
  box-shadow: 5px 5px 2.5px #dfd9d9;
}
.input {
  box-shadow: 5px 5px 2.5px #dfd9d9;
  border-radius: 0;
}

.inputEnd {
  box-shadow: 5px 5px 2.5px #dfd9d9;
  border-radius: 0;
}

.textCard {
  text-align: left;
  font-size: 12px;
  text-align: left;
}

.titleModal {
  text-align: center;
}
.buttonCancel {
  padding: 8px 20px !important;
  border: 1px solid transparent;
  box-shadow: 5px 5px 2.5px #dfd9d9;
  background-color: white;
  color: black;
  border-radius: 0;
}
.buttonCancel:hover {
  border: 1px solid #dd2b25;
  background-color: white;
  color: black;
}
.subtitle {
  text-align: center;
  font-size: 12px !important;
}
