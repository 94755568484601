.colLogo {
  box-shadow: 5px 5px 2.5px #dfd9d9;
  border: 1px solid #dfd9d9;
  margin: 2vw;
  cursor: pointer;
}
.rowHeaders {
  width: 100%;
  align-items: center;
}
.logo {
  width: 8vw;
}

.logoPartner {
  width: 8vw;
}
.rowPartners {
  box-shadow: 5px 5px 2.5px #dfd9d9;
  border: 1px solid #dfd9d9;
  justify-content: space-evenly;
}
