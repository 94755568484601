@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@200;300;400;500;600;700;800;900&display=swap');

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #28262b;
}
hr {
  width: 30%;
  background-color: #dd2b25;
  margin: 0;
  height: 0.3vw !important;
}
h1 {
  font-size: 4.5vw !important;
  color: white;
  line-height: 1.5;
  font-weight: 800;
  text-transform: uppercase;
}

.breadcrumb {
  background-color: inherit;
}

.breadcrumb a {
  color: #28262b !important;
}
h2 {
  font-size: 30px !important;
  color: #28262b;
  line-height: 1.5;
  font-weight: 800;
  padding: 20px;
  text-transform: uppercase;
}
h3 {
  margin: 20px;
  font-size: 25px;
  color: #28262b;
  line-height: 1.4;
  font-weight: 800;
}
button {
  padding: 8px 20px;
  background-color: #dd2b25;
  font-size: 18px;
  font-weight: 500;
  color: white;
  border: 1px solid transparent;
  border-radius: 0 !important;
}
button:hover {
  background-color: transparent;
  color: #dd2b25;
  border: 1px solid #dd2b25;
  transition: all 0.4s ease-in-out;
}
p {
  font-size: 16px;
  font-weight: 500;
}
.redCrea {
  background-color: #dd2b25;
  margin-top: 4vw;
}
.lightGreyCrea {
  background-color: #f9f9f9;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.hrFooter {
  height: 0.1vw !important;
  width: 40vw;
  margin: 6vw 30vw 2vw 30vw;
}
.button {
  padding: 10px 20px !important;
  background-color: #dd2b25 !important;
  font-size: 15px !important;
  color: white !important;
  border: 1px solid transparent !important;
  border-radius: 0 !important;
}

/*Editeur de texte */
.ck-content {
  height: 20vw;
}

/* Fil d'ariane */
.breadcrumb {
  margin: 1vw 0 2vw 0;
  background-color: transparent !important;
}
.breadcrumb a {
  color: black;
  text-decoration: none;
}
.breadcrumb a:hover {
  color: blue;
  text-decoration: none;
}
/*textarea formualire contact*/
.textarea .form-control {
  height: 20vh;
}

/*Menu déroulant choix faq admin*/
.dropdown-menu {
  width: auto !important;
  text-align: center;
}
.dropdown-item {
  font-size: 0.8vw;
}

/*modal faq*/
.modal-dialog {
  max-width: 800px;
}
.modal-title {
  width: 100%;
}
