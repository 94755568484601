body {
  font-family: 'Raleway';
}
.userInfos {
  background-color: #dd2b25;
  margin-top: 2vw;
  margin-bottom: 2vh;
  padding: 0;
}
.dashboardTable {
  margin-top: 3vw;
  margin-bottom: 2vw;
  margin-right: auto;
  margin-left: auto;
  padding: 0;
}
.dashboard {
  margin: 0 !important;
  padding: 0;
}
.fil {
  margin-left: 15vw;
}
