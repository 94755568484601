.theadBackground {
  background-color: black;
  color: white;
}

.buttonInput {
  background-color: transparent;
  color: black;
  border: 1px solid black;
  margin: 5px;
}

.cross {
  cursor: pointer;
}
