.bkgFooter {
  background-color: #f9f9f9;
  text-align: left;
  color: #acacac;
}

.colorLink {
  color: #acacac;
}

.iconFooter {
  color: #dd2b25;
}

.imgWidthCrea {
  width: 50%;
}
.imgWidthNetworking {
  width: 60%;
}

.tagMl {
  color: #28262b;
  text-align: center;
}

.textBold {
  font-weight: bold;
}

@media screen and (max-width: 640px) {
  .bkgFooter {
    text-align: center;
  }
  .imgWidthCrea {
    width: 50%;
  }
  .imgWidthNetworking {
    width: 80%;
  }
}
