.button {
  color: #007bff;
  font-weight: bold;
}
.buttonCancel {
  background-color: inherit;
  border: none;
  color: #dd2b25;
  margin: 5px 10px;
}

.buttonCancel:hover {
  background-color: inherit;
  border: none;
  color: #dd2b25;
  margin: 5px 10px;
}
