.logo_crea {
  width: 5vw;
  margin-left: 2vw;
}
.navbar {
  background-color: rgb(255, 255, 255, 0.8);
  position: fixed !important;
  width: 100%;
  margin-top: 7vh;
  z-index: 500;
  justify-content: space-between !important;
}

.alignCenter {
  align-items: center !important;
}

.navlink:hover {
  color: #dd2b25;
}
@media screen and (max-width: 800px) {
  .partenaires,
  .contact {
    padding-left: inherit;
    padding-right: inherit;
  }

  .marginLeftResp {
    margin-left: 0px !important;
  }

  .logo_crea {
    display: none;
  }
  .toggleButton {
    /* background-color: #dd2b25; */
    padding: 3px 8px;
  }

  .navbar {
    background-color: white;
  }
}
