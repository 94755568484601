.theadBackground {
  background-color: #dd2b25;
  color: white;
}

.buttonInput {
  background-color: transparent;
  color: black;
  border: 1px solid black;
  margin: 5px;
}

.export {
  justify-content: right;
}

.thFontsize {
  font-size: 12px;
}
.okButton {
  background-color: white;
  color: #dd2b25;
  border-color: #dd2b25;
  width: 100%;
}

.suppButton {
  background-color: white;
  color: #dd2b25;
  border-color: #dd2b25;
}
.okButton:hover {
  background-color: #dd2b25;
  color: white;
  border-color: white;
}
.inputSearch {
  border-radius: 0;
  border-color: #dd2b25;
}

.paddingRightNone {
  padding-right: inherit !important;
}
.cross {
  cursor: pointer;
}
