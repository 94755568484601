.cardSubtitleDate {
  font-size: 1rem;
  text-align: left;
  font-weight: 300;
  padding-bottom: 1.1vw;
  font-style: italic;
}
.cardCss {
  margin-top: 2vw;
}
.cardTitle {
  font-size: 1.2rem;
  text-align: left;
}

.cardSubtitle {
  text-align: left;
  font-weight: 300;
  font-size: 1.25rem;
  font-style: italic;
  padding-bottom: 0.5vw;
}
.cardText {
  text-align: left !important;
  height: 8vh;
  overflow: hidden;
  text-overflow: ellipsis;
}

.button {
  color: #dd2b25;
  background-color: inherit;
  border: 1px solid transparent;
}
.button:hover {
  color: #dd2b25;
  background-color: inherit;
  border: 1px solid #dd2b25;
}

.button:focus {
  color: #dd2b25;
  background-color: inherit;
  border: 1px solid #dd2b25;
}
