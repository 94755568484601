/* .graph {
  width: 60vw;
  margin-bottom: 10vh;
} */

.userTypes {
  background-color: #28262b;
  color: white;
  padding: 10px;
}

.pie {
  box-shadow: 5px 5px 2.5px #dfd9d9;
  border: 1px solid #dfd9d9;
  padding: 0;

  width: 100%;
}
.line {
  box-shadow: 5px 5px 2.5px #dfd9d9;
  border: 1px solid #dfd9d9;
  padding: 0;
  padding-bottom: 4vh;
}
/* .bar2 {
  margin-top: 10vh;
} */
/* .containerGeneral {
  margin-bottom: 10vh;
} */
.nbtotal {
  margin-top: 2vw;
  text-align: center;
  margin-left: 2vw;
  font-size: 1vw;
  font-weight: bold;
}
.nbtotal2 {
  text-align: center;
  margin-left: 2vw;
  font-size: 1vw;
  font-weight: bold;
}
.nb {
  text-align: left;
  margin-left: 7vw;
  font-size: 0.8vw;
}
