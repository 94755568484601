.containerQR {
  box-shadow: 5px 5px 2.5px #dfd9d9;
  border: 1px solid #dfd9d9;
  margin-bottom: 4vh;
  padding-bottom: 2vh;
}
.addTypePost {
  margin: 2vw 0;
  background-color: #f9f9f9;
  border: 1px solid #e7e5e5;
  box-shadow: 5px 5px 2.5px #dfd9d9;
  padding: 2vw;
}
.searchBar {
  background-color: #28262b;
  padding: 0.8vw;
}
.faqTitle {
  color: white;
  padding-top: 0.8vh;
}
.input {
  box-shadow: 5px 5px 2.5px #dfd9d9;
  border-radius: 0;
}
.modalText {
  margin-top: auto;
  margin-bottom: auto;
}
.inputQR {
  margin: 1vw 0;
  box-shadow: 5px 5px 2.5px #dfd9d9;
  border-radius: 0;
}

.imgCard {
  width: 100%;
}
.titleModal {
  text-align: center;
  margin-top: 1vw;
}
.subtitle {
  text-align: center;
  font-size: 0.8vw !important;
  margin-bottom: 2vw;
}
.buttonCancel {
  padding: 8px 20px !important;
  border: 1px solid transparent;
  box-shadow: 5px 5px 2.5px #dfd9d9;
  background-color: white;
  color: black;
  border-radius: 0;
}
.buttonCancel:hover {
  border: 1px solid #dd2b25;
  background-color: white;
  color: black;
}
